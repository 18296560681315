import React, { FC } from 'react'
import Head from 'next/head'

import { TagProps } from '../../atoms/Tag'
import { ActionButtonProps } from '../../atoms/ActionButton'
import { HeroProps } from '../Hero'
import RecipeCard, { RecipeCardProps } from '../RecipeCard'
import ArticleCard, { ArticleCardProps } from '../ArticleCard'
import ContentCard, { CardContentType } from '../ContentCard'
import FolderCard from '../FolderCard'
import CheeseCard from '../CheeseCard'
import { PaginationProps } from '../Pagination'

import * as SC from './styled'

export type CardsBlockProps = MinimalSeoBlock & {
  className?: string
  title?: string
  tags?: TagProps[]
  text?: string
  highlighted?: HeroProps
  cards?: (RecipeCardProps | ArticleCardProps)[]
  actionButtonProps?: ActionButtonProps
  paginationProps?: PaginationProps
  containerRef?: React.Ref<HTMLDivElement>
  jsonLd?: string
  texts?: {
    noResult?: string
  }
}

const CardsBlock: FC<CardsBlockProps> = ({
  className,
  htmlTag,
  title,
  text,
  htmlTitleTag,
  tags,
  highlighted,
  cards,
  actionButtonProps,
  paginationProps,
  containerRef,
  jsonLd,
  texts,
}) => {
  return (
    <SC.CardsBlock className={className} as={htmlTag} ref={containerRef}>
      {(title || text || tags) && (
        <SC.Head>
          {title && <SC.Title as={htmlTitleTag}>{title}</SC.Title>}
          {text && <SC.Text>{text}</SC.Text>}
          {tags && <SC.Tags tags={tags} />}
        </SC.Head>
      )}
      {highlighted && (
        <SC.Highlight htmlTitleTag={htmlTitleTag} {...highlighted} />
      )}
      {cards && (
        <SC.Cards $length={cards?.length} key={cards?.length}>
          {cards?.map((card, i) => {
            switch (card?.type) {
              case CardContentType.Article:
                return <ArticleCard key={i} {...card} />
              case CardContentType.Folder:
                return <FolderCard key={i} {...card} />
              case CardContentType.Recipe:
                return <RecipeCard key={i} {...card} />
              case CardContentType.Cheese:
                return <CheeseCard key={i} {...card} />
              case CardContentType.Plate:
                return <FolderCard key={i} {...card} />
              default:
                return <ContentCard key={i} {...card} />
            }
          })}
        </SC.Cards>
      )}
      {texts?.noResult && cards && cards?.length === 0 && (
        <SC.NoResult>{texts.noResult}</SC.NoResult>
      )}
      {actionButtonProps &&
        (actionButtonProps.href || actionButtonProps.onClick) && (
          <SC.Button {...actionButtonProps} />
        )}
      {paginationProps && <SC.Paginate {...paginationProps} />}
      {jsonLd && (
        <Head>
          <script type="application/ld+json">{jsonLd}</script>
        </Head>
      )}
    </SC.CardsBlock>
  )
}

export default CardsBlock

import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import TagComponent from '../../atoms/Tag'
import ContentCard from '../ContentCard'
import { Title } from '../ContentCard/styled'

export const Card = styled(ContentCard)<{ $tiny: boolean }>`
  & ${Title} {
    text-align: left;

    @media ${mq(sizes.tablet)} {
      text-align: ${({ $tiny }) => ($tiny ? 'start' : 'center')};
      margin-bottom: ${({ $tiny }) => ($tiny ? '0.5rem' : 0)};
    }
  }
`

export const Content = styled.div`
  width: 100%;
`

export const Tags = styled.div<{ $tiny: boolean }>`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 1.3rem;
  & > * {
    display: none;
    &:first-child {
      display: flex;
    }
  }
  @media ${mq(sizes.tablet)} {
    margin: 1.2rem 0;
    justify-content: ${({ $tiny }) => ($tiny ? 'flex-start' : 'center')};
    & > * {
      display: flex;
    }
  }
`
export const Tag = styled(TagComponent)``

export const Text = styled.p<{ $tiny: boolean }>`
  margin: 0 0 1rem 0;
  ${({ theme }) => theme.textStyles.textSmall}
  color: ${({ theme }) => theme.colors.bismark};

  @media ${mq(sizes.tablet)} {
    margin: 1rem 0 0 0;
    margin-top: 0;
    text-align: ${({ $tiny }) => ($tiny ? 'start' : 'center')};
  }
`

import styled from 'styled-components'
import ReactPaginate from 'react-paginate'

import { mq, sizes } from '../../../theme'
import Icon from '../../atoms/Icon'

export const Container = styled(ReactPaginate)<{ $isHidden?: boolean }>`
  padding: 0;
  list-style: none;
  display: ${({ $isHidden }) => ($isHidden === true ? 'none' : 'flex')};
  align-items: center;
  max-width: 100%;
  align-items: center;
  justify-content: center;
  &:not(:first-child) {
    margin-top: 1.5rem;
  }
  & > :not(:first-child) {
    margin-left: 1rem;
  }
  & a {
    ${({ theme }) => theme.textStyles.textSmall}
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.8rem;
    height: 2.8rem;
    border-radius: 0.5rem;
    background: ${({ theme }) => theme.colors.pureWhite};
    color: ${({ theme }) => theme.colors.arapawa};
    transition: all 300ms ease-in-out;
  }
  & a:hover:not([aria-disabled='true']),
  & .selected a {
    background: ${({ theme }) => theme.colors.arapawa};
    color: ${({ theme }) => theme.colors.pureWhite};
  }
  & a[aria-disabled='true'] {
    color: ${({ theme }) => theme.colors.arapawa};
    opacity: 0.5;
    pointer-events: none;
  }
  @media ${mq(sizes.mobile)} {
    &:not(:first-child) {
      margin-top: 3rem;
    }
  }
`
export const Nav = styled(Icon)``
export const NavPrev = styled(Nav)``
export const NavNext = styled(Nav)``

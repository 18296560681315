import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import TagComponent from '../../atoms/Tag'
import ContentCard from '../ContentCard'
import { Title } from '../ContentCard/styled'

export const Card = styled(ContentCard)`
  & ${Title} {
    text-align: left;
  }
`

export const Tags = styled.div`
  display: flex;
  justify-content: flex-start;
  width: 100%;
  flex-wrap: wrap;
  gap: 1.3rem;
  & > * {
    display: none;
    &:first-child {
      display: flex;
    }
  }
  @media ${mq(sizes.tablet)} {
    & > * {
      display: flex;
    }
  }
`
export const Tag = styled(TagComponent)``

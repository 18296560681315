import styled from 'styled-components'

import { mq, sizes } from '../../../theme'
import TagsCloud from '../TagsCloud'
import Hero from '../Hero'
import ActionButton from '../../atoms/ActionButton'
import Pagination from '../Pagination'

export const CardsBlock = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;

  padding: 0 1.5rem;
  gap: 3rem;
  @media ${mq(sizes.tablet)} {
    padding: 0 2rem;
  }
  @media ${mq(sizes.desktop)} {
    padding: 0 4rem;
  }
  @media print {
    display: none;
  }
`

export const Head = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  gap: 1.5rem;
`

export const Title = styled.h2`
  ${({ theme }) => theme.textStyles.titleH3};
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  width: 100%;
  margin: 0;
  flex-grow: 1;
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH1};
  }
`

export const Tags = styled(TagsCloud)`
  margin: 0;
  padding: 0;
`

export const Text = styled.p`
  ${({ theme }) => theme.textStyles.textMedium};
  text-align: center;
  color: ${({ theme }) => theme.colors.arapawa};
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.textLarge};
  }
`

export const Highlight = styled(Hero)`
  padding: 0;
`

export const Cards = styled.div<{ $length?: number }>`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-start;
  gap: 1.5rem;
  @media ${mq(sizes.tablet)} {
    align-items: stretch;
    & > * {
      width: calc(50% - 1rem);
    }
  }
  @media ${mq(sizes.desktop)} {
    & > * {
      flex-grow: 0;
      width: calc(${100 / 3}% - 1rem);
    }
  }
`
export const NoResult = styled(Text)``

export const Button = styled(ActionButton)`
  margin-top: 1rem;
`
export const Paginate = styled(Pagination)`
  padding-top: 2rem;
  border-top: 1px solid ${({ theme }) => theme.colors.gainsboro};
  margin-bottom: 2rem;
  width: 100%;
  margin-top: 1rem;
  &:not(:first-child) {
    margin-top: 1rem;
  }
`

import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'

import { TagsCloudVariant } from './types'

export const TagsCloud = styled.section<{ $variant?: TagsCloudVariant }>`
  position: relative;
  padding: 3rem 1.5rem;
  ${({ $variant, theme }) =>
    $variant === TagsCloudVariant.Secondary &&
    css`
      &::before {
        content: '';
        height: 0.1rem;
        position: absolute;
        top: 0;
        left: 1.5rem;
        right: 1.5rem;
        background: ${theme.colors.silver};
      }
    `};
  @media ${mq(sizes.tablet)} {
    padding: 3rem 4rem;
    ${({ $variant }) =>
      $variant === TagsCloudVariant.Secondary &&
      css`
        &::before {
          left: 4rem;
          right: 4rem;
        }
      `};
  }
  @media print {
    display: none;
  }
`

export const Tags = styled.nav<{ $variant?: TagsCloudVariant }>`
  display: flex;
  justify-content: ${({ $variant }) =>
    $variant === TagsCloudVariant.Secondary ? 'flex-start' : 'center'};
  flex-wrap: wrap;
  gap: 1.3rem;
`

export const Title = styled.h2<{ $variant?: TagsCloudVariant }>`
  ${({ theme }) => theme.textStyles.titleH3}
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  ${({ $variant }) =>
    $variant === TagsCloudVariant.Secondary
      ? css`
          text-align: left;
          padding-bottom: 3rem;
        `
      : css`
          text-align: center;
          padding-bottom: 1.5rem;
        `};
  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH2}
  }
`

import React, { FC } from 'react'

import { PictureProps } from '../../atoms/Picture'
import { TagProps } from '../../atoms/Tag'
import { LinkProps } from '../../atoms/Link'
import { IconProps, Icons } from '../../atoms/Icon'
import { Scores } from '../../atoms/Score'
import { ListIconHtmlProps } from '../../atoms/ListIconHtml'

import { HeroVariant } from './styled'
import * as SC from './styled'

export type HeroProps = MinimalSeoBlock & {
  className?: string
  imageProps?: PictureProps
  mobileImageProps?: PictureProps
  banner?: string
  brandLogoProps?: PictureProps
  brandLogoLinkProps?: LinkProps
  children?: React.ReactNode | React.ReactElement | React.ReactNodeArray
  topTags?: TagProps[]
  topTagsNoMargin?: boolean
  title?: string
  text?: string
  bottomTags?: TagProps[]
  linkProps?: LinkProps
  buttonLabel?: string
  score?: Scores
  list?: ListIconHtmlProps
  duration?: string
  isFullLink?: boolean
  isGeneric?: boolean
  variant?: HeroVariant
  badges?: IconProps[]
}

const Hero: FC<HeroProps> = ({
  className,
  htmlTag,
  imageProps,
  mobileImageProps,
  banner,
  brandLogoProps,
  brandLogoLinkProps,
  children,
  topTags,
  topTagsNoMargin,
  bottomTags,
  title,
  htmlTitleTag,
  text,
  linkProps,
  buttonLabel,
  score,
  duration,
  isFullLink,
  isGeneric,
  list,
  variant = HeroVariant.normal,
  badges,
}) => {
  const MainComponent: FC<
    (LinkProps | React.HTMLAttributes<HTMLDivElement>) & {
      $variant?: HeroVariant
    }
  > = linkProps && isFullLink ? SC.MainLink : SC.Main
  const CardComponent: FC<
    (LinkProps | React.HTMLAttributes<HTMLDivElement>) & {
      $variant?: HeroVariant
    }
  > = linkProps && !isFullLink ? SC.CardLink : SC.Card

  return (
    <SC.Hero className={className} $variant={variant} as={htmlTag}>
      <MainComponent $variant={variant} {...(isFullLink && linkProps)}>
        {variant === HeroVariant.positive ? (
          <SC.HeroImage $variant={variant}>
            {!mobileImageProps && (
              <SC.BackgroundImage $variant={variant} {...imageProps} />
            )}
            {mobileImageProps && (
              <>
                <SC.MobileBackgroundImage
                  $variant={variant}
                  {...mobileImageProps}
                />
                <SC.DesktopBackgroundImage $variant={variant} {...imageProps} />
              </>
            )}
            {banner && <SC.Banner>{banner}</SC.Banner>}
          </SC.HeroImage>
        ) : (
          <>
            {!mobileImageProps && (
              <SC.BackgroundImage
                $variant={variant}
                {...imageProps}
                placeholder={!!brandLogoProps}
              />
            )}
            {mobileImageProps && (
              <>
                <SC.MobileBackgroundImage
                  $variant={variant}
                  {...mobileImageProps}
                  placeholder={!!brandLogoProps}
                />
                <SC.DesktopBackgroundImage
                  $variant={variant}
                  {...imageProps}
                  placeholder={!!brandLogoProps}
                />
              </>
            )}
          </>
        )}

        {badges && (
          <SC.Badges>
            {badges?.map((badge, i) => (
              <SC.Badge {...badge} key={i} />
            ))}
          </SC.Badges>
        )}

        <CardComponent $variant={variant} {...(!isFullLink && linkProps)}>
          <SC.Bezel
            $variant={variant}
            viewBox="0 0 335 11"
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
          >
            <path d="M0 0.375V10.375H168.303C99.7883 10.3705 38.8536 6.45373 0 0.375ZM168.537 10.375H335V0.658797C296.078 6.57536 235.98 10.3706 168.537 10.375Z" />
          </SC.Bezel>
          {brandLogoProps && (
            <>
              <SC.LogoContainer
                {...brandLogoLinkProps}
                $isGeneric={isGeneric}
                $variant={variant}
              >
                <SC.Logo
                  $variant={variant}
                  width={80}
                  height={80}
                  {...brandLogoProps}
                />
              </SC.LogoContainer>
              <SC.LogoSpacer $isGeneric={isGeneric} />
            </>
          )}
          <SC.Content>
            {topTags && (
              <SC.TopTags $variant={variant} $noMargin={topTagsNoMargin}>
                {topTags?.map((tag, i) => (
                  <SC.TopTag
                    key={i}
                    {...tag}
                    $active={tag.active ? true : false}
                    $variant={variant}
                  />
                ))}
              </SC.TopTags>
            )}
            {title && (
              <SC.Title
                as={htmlTitleTag}
                $withNoMargintags={topTagsNoMargin}
                $variant={variant}
              >
                {title}
              </SC.Title>
            )}
            {text && (
              <SC.Text dangerouslySetInnerHTML={{ __html: text ?? '' }} />
            )}
            {bottomTags && (
              <SC.BottomTags>
                {bottomTags?.map((tag, i) => (
                  <SC.Tag key={i} {...tag} />
                ))}
              </SC.BottomTags>
            )}
            {(duration || score) && (
              <SC.RecipeInfos>
                {duration && (
                  <SC.Duration>
                    <SC.DurationIcon icon={Icons.timer} />
                    {duration}
                  </SC.Duration>
                )}
                {score && duration && <SC.RecipeInfosSeparator />}
                {score && <SC.Score value={score} />}
              </SC.RecipeInfos>
            )}
            {list && <SC.IconsList {...list} />}
            {linkProps && buttonLabel && <SC.Button>{buttonLabel}</SC.Button>}
          </SC.Content>
        </CardComponent>
      </MainComponent>
      {children}
    </SC.Hero>
  )
}

export default Hero

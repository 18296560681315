import styled, { css } from 'styled-components'

import { mq, sizes } from '../../../theme'
import Picture from '../../atoms/Picture'
import TagComponent from '../../atoms/Tag'
import Link from '../../atoms/Link'
import Icon from '../../atoms/Icon'
import ScoreComponent from '../../atoms/Score'
import ListIconHtml from '../../atoms/ListIconHtml'

export enum HeroVariant {
  normal = 'normal',
  positive = 'positive',
  fullWidth = 'fullWidth',
}

export const Hero = styled.article<{ $variant?: HeroVariant }>`
  display: flex;
  width: 100%;
  padding: 0 1.5rem;
  @media ${mq(sizes.tablet)} {
    padding: 0;
  }
`
const mainStyles = css<{ $variant?: HeroVariant }>`
  border-radius: ${({ $variant }) =>
    $variant && $variant === HeroVariant.positive ? '2rem 2rem 0 0' : '2rem'};
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: ${({ $variant }) =>
    $variant && $variant === HeroVariant.positive ? 'flex-end' : 'center'};
  background: rgba(0, 0, 0, 0.1);
  @media ${mq(sizes.tablet)} {
    border-radius: ${({ $variant }) =>
      $variant && $variant === HeroVariant.positive ? '3rem 3rem 0 0' : '3rem'};
    min-height: 30rem;
  }
`
export const Main = styled.div`
  ${mainStyles}
`
export const MainLink = styled(Link)`
  ${mainStyles}
`

export const HeroImage = styled.div<{ $variant?: HeroVariant }>`
  min-height: ${({ $variant }) =>
    $variant && $variant === HeroVariant.positive ? 'auto' : '21rem'};
  position: relative;
  width: 100%;

  @media ${mq(sizes.tablet)} {
    min-height: ${({ $variant }) =>
      $variant && $variant === HeroVariant.positive ? 'auto' : '30rem'};
  }

  @media print {
    display: none;
  }
`

export const BackgroundImage = styled(Picture)<{ $variant?: HeroVariant }>`
  display: flex;
  height: ${({ $variant }) =>
    $variant && $variant === HeroVariant.positive ? 'auto' : '21rem'};
  width: 100%;
  aspect-ratio: ${({ $variant }) =>
    $variant && $variant === HeroVariant.positive ? '4/3' : 'auto'};
  & img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }

  @media ${mq(sizes.tablet)} {
    position: ${({ $variant }) =>
      $variant && $variant === HeroVariant.positive ? 'inherit' : 'absolute'};
    aspect-ratio: ${({ $variant }) =>
      $variant && $variant === HeroVariant.positive ? '5/2' : 'auto'};
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  @media print {
    display: none;
  }
`

export const DesktopBackgroundImage = styled(BackgroundImage)`
  display: none;
  @media ${mq(sizes.tablet)} {
    display: block;
  }
`
export const MobileBackgroundImage = styled(BackgroundImage)`
  @media ${mq(sizes.tablet)} {
    display: none;
  }
`

export const Badges = styled.div`
  position: absolute;
  display: flex;
  flex-flow: column;
  gap: 0.5rem;
  top: 1rem;
  right: 1rem;

  @media print {
    display: none;
  }
`

export const Badge = styled(Icon)`
  padding: 0.2rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 50%;
  width: 5rem;
  height: 5rem;
`

export const Banner = styled.p`
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -45%) rotate(-2deg);
  border-top: 4px solid ${({ theme }) => theme.colors.pureWhite};
  border-bottom: 4px solid ${({ theme }) => theme.colors.pureWhite};
  padding: 1.5rem 3rem 1rem 3rem;
  text-align: center;
  clip-path: polygon(
    0 0,
    100% 0%,
    calc(100% - 20px) 50%,
    100% 100%,
    0 100%,
    calc(0% + 20px) 50%
  );
  ${({ theme }) => theme.textStyles.titleH4};
  color: ${({ theme }) => theme.colors.pureWhite};
  margin: 0;
  background-color: ${({ theme }) => theme.colors.bayLeaf};
  filter: blur(0.01px);
  width: max-content;
  max-width: 80%;

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH1};
    padding: 2rem 3rem 1.5rem 3rem;
  }

  @media print {
    display: none;
  }
`

const cardStyles = css<{ $variant?: HeroVariant }>`
  position: relative;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ $variant }) =>
    $variant && $variant === HeroVariant.positive
      ? css`
          @media ${mq(sizes.tablet)} {
            width: 100%;
          }
        `
      : css`
          @media ${mq(sizes.tablet)} {
            width: 30rem;
            border-radius: 2rem;
            margin: 9rem 4rem;
          }
        `}

  ${({ $variant }) =>
    $variant &&
    $variant === HeroVariant.fullWidth &&
    css`
      @media ${mq(sizes.tablet)} {
        width: 60rem;
        border-radius: 2rem;
        margin: 15rem auto 10rem auto;
      }
    `}
`

export const Card = styled.div`
  ${cardStyles}
`
export const CardLink = styled(Link)`
  ${cardStyles}
`

export const Bezel = styled.svg<{ $variant?: HeroVariant }>`
  position: absolute;
  top: -0.9rem;
  width: 100%;
  height: 1rem;
  & path {
    fill: ${({ theme }) => theme.colors.pureWhite};
  }
  @media ${mq(sizes.tablet)} {
    display: ${({ $variant }) =>
      $variant && $variant === HeroVariant.positive ? 'block' : 'none'};
  }
`

export const LogoContainer = styled(Link)<{
  $isGeneric?: boolean
  $variant?: HeroVariant
}>`
  position: absolute;
  width: 18rem;
  height: 18rem;
  padding-top: 2.4rem;
  top: -5.8rem;
  background-color: ${({ theme }) => theme.colors.pureWhite};
  border-radius: 100%;
  flex-shrink: 0;
  display: flex;
  align-items: flex-start;
  justify-content: center;

  @media ${mq(sizes.tablet)} {
    ${({ $variant }) =>
      $variant &&
      $variant === HeroVariant.positive &&
      css`
        width: 40rem;
        height: 40rem;
        top: -14rem;
      `}

    ${({ $isGeneric }) =>
      $isGeneric &&
      css`
        position: initial;
        top: 0;
        margin-top: 1rem;
        width: 100%;
        height: initial;
        padding: 0;
      `}
  }

  @media print {
    display: none;
  }
`

export const LogoSpacer = styled.div<{ $isGeneric?: boolean }>`
  width: 100%;
  height: 4.8rem;

  @media ${mq(sizes.tablet)} {
    ${({ $isGeneric }) =>
      $isGeneric &&
      css`
        display: none;
      `}
  }
`

export const Content = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Logo = styled(Picture)<{ $variant?: HeroVariant }>`
  width: 8rem;
  height: 8rem;

  @media ${mq(sizes.tablet)} {
    ${({ $variant }) =>
      $variant &&
      $variant === HeroVariant.positive &&
      css`
        width: 12rem;
        height: 12rem;
      `}
  }

  & img {
    height: auto;
    object-fit: contain;
  }
`

export const Tags = styled.div<{ $variant?: HeroVariant }>`
  display: flex;
  gap: 1.3rem;

  ${({ $variant }) =>
    $variant && $variant === HeroVariant.positive
      ? css`
          margin-bottom: 2rem;
          overflow: auto visible;
          width: 100%;
          padding: 0 1.5rem;
          -ms-overflow-style: none;
          scrollbar-width: none;
          &::-webkit-scrollbar {
            display: none;
          }
        `
      : css`
          flex-wrap: wrap;
          justify-content: center;
        `}

  @media ${mq(sizes.tablet)} {
    justify-content: center;

    ${({ $variant }) =>
      $variant &&
      $variant === HeroVariant.positive &&
      css`
        margin: 0;
      `}
  }

  @media print {
    display: none;
  }
`

export const TopTag = styled(TagComponent)<{
  $active: boolean
  $variant: HeroVariant
}>`
  border: 1px solid ${({ theme }) => theme.colors.greyChateau};
  min-width: max-content;

  ${({ $variant, $active }) =>
    $variant === HeroVariant.positive && $active
      ? css`
          background-color: ${({ theme }) => theme.colors.arapawa};
        `
      : $variant === HeroVariant.positive &&
        !$active &&
        css`
          background-color: transparent;
        `}

  ${({ $variant }) =>
    $variant === HeroVariant.positive &&
    css`
      padding: 1.3rem 1.6rem 1rem 1.6rem;
      border-radius: 2rem;
    `}
`

export const Tag = styled(TagComponent)`
  border: 1px solid ${({ theme }) => theme.colors.greyChateau};
  min-width: max-content;
`

export const TopTags = styled(Tags)<{
  $noMargin?: boolean
  $variant: HeroVariant
}>`
  padding-top: 1rem;
  ${({ $noMargin }) =>
    $noMargin &&
    css`
      padding-top: 0;
      & > * {
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        border-width: 0;
        padding-bottom: 0.3rem;
      }
      & > *:only-child {
        padding-left: 3rem;
        padding-right: 3rem;
      }
    `}

  @media print {
    display: none;
  }
`
export const BottomTags = styled(Tags)`
  padding-bottom: 2rem;
`

export const Title = styled.h2<{
  $withNoMargintags?: boolean
  $variant: HeroVariant
}>`
  ${({ theme }) => theme.textStyles.titleH4};
  margin: 0;
  color: ${({ theme }) => theme.colors.arapawa};
  text-align: center;
  width: 100%;
  padding: 1.5rem 2rem;
  &:last-child {
    padding-bottom: 2rem;
  }
  ${({ $withNoMargintags }) =>
    $withNoMargintags &&
    css`
      padding-top: 3rem;
      padding-bottom: 2rem;
    `}

  @media ${mq(sizes.tablet)} {
    ${({ theme }) => theme.textStyles.titleH3};

    ${({ $variant }) =>
      $variant === HeroVariant.positive &&
      css`
        ${({ theme }) => theme.textStyles.titleH1};
        padding: 5rem 3rem 2rem 3rem;
      `}
  }

  @media print {
    margin-top: 1rem;
  }
`

export const Text = styled.div`
  ${({ theme }) => theme.textStyles.textMedium};
  color: ${({ theme }) => theme.colors.bismark};
  padding: 0.5rem 2rem 2rem;
  text-align: center;
`

export const Button = styled.div`
  margin-top: 0.5rem;
  ${({ theme }) => theme.textStyles.titleH6};
  background-color: ${({ theme }) => theme.colors.creamCan};
  color: ${({ theme }) => theme.colors.arapawa};
  padding: 1.5rem 2rem;
  transition: color 0.3s, background-color 0.3s;
  width: 100%;
  text-align: center;
  a:hover & {
    background-color: ${({ theme }) => theme.colors.arapawa};
    color: ${({ theme }) => theme.colors.creamCan};
  }
  a:focus-visible & {
    background-color: ${({ theme }) => theme.colors.arapawa};
    color: ${({ theme }) => theme.colors.creamCan};
  }
  border-bottom-left-radius: 2rem;
  border-bottom-right-radius: 2rem;
`

export const RecipeInfos = styled.div`
  display: flex;
  align-items: center;
  padding-bottom: 2rem;

  @media print {
    display: none;
  }
`

export const RecipeInfosSeparator = styled.div`
  background-color: ${({ theme }) => theme.colors.silver};
  width: 1px;
  height: 3.2rem;
  margin: 0 1rem;
`

export const Score = styled(ScoreComponent)`
  height: 3.2rem;
`

export const IconsList = styled(ListIconHtml)``

export const Duration = styled.div`
  ${({ theme }) => theme.textStyles.textSmallStrong};
  color: ${({ theme }) => theme.colors.ruby};
  display: flex;
`
export const DurationIcon = styled(Icon)`
  color: ${({ theme }) => theme.colors.ruby};
  width: 2rem;
  height: 2rem;
  margin-right: 0.4rem;
`

import tracking from 'src/tracking'

import { Maybe, Tag, TagType } from '../../graphql/generated/api-graphql'
import { TagVariant } from '../../components/atoms/Tag/styled'
import { TagProps } from '../../components/atoms/Tag'
import Router, { routes } from '../../routes/Router'
import { actions } from '../../redux'

const resolveTagVariant = (type?: string) =>
  type === TagType.Difficulty
    ? TagVariant.primary
    : TagType.BalanceDiet
    ? TagVariant.diet
    : TagType.Type
    ? TagVariant.meal
    : TagVariant.primary
export const transformTagForCloud = (
  tag?: Tag | Maybe<Tag>,
  page?: string,
  isInSearchEngine?: boolean,
  dispatchCallback?: any
): TagProps => {
  return {
    label: tag?.name ?? '',
    href: Router.getRouteUrl(routes.tag, { slug: tag?.slug }),
    onClick: () => {
      if (isInSearchEngine && dispatchCallback) {
        dispatchCallback?.(
          actions.search.setSearchDataLayer({
            search_bar_location: 'content',
            search_type: 'suggestion',
          })
        )
      } else {
        tracking.tag(tag?.name ?? '', page ?? '')
      }
    },
  }
}

export const transformTagForHero = (
  tag?: Tag | Maybe<Tag>,
  page?: string
): TagProps => {
  return {
    label: tag?.name,
    variant: resolveTagVariant(tag?.type),
    href: Router.getRouteUrl(routes.tag, { slug: tag?.slug }),
    onClick: () => tracking.tag(tag?.name ?? '', page ?? ''),
  }
}

export const transformTagForCard = (tag?: Tag | Maybe<Tag>): TagProps => {
  return {
    label: tag?.name,
    variant: resolveTagVariant(tag?.type),
  }
}

import styled, { css } from 'styled-components'

import Html from '../Html'
import Icon from '../Icon'
import { mq, sizes } from '../../../theme'
import ActionButton from '../ActionButton'

import { ListIconHtmlVariant } from './types'

export const ListIconHtml = styled.ul<{ $variant?: ListIconHtmlVariant }>`
  list-style: none;
  display: flex;
  flex-flow: column;
  margin: 0;
  padding: 0;
  ${({ $variant }) =>
    $variant === ListIconHtmlVariant.Positive &&
    css`
      margin-bottom: 2rem;
      @media ${mq(sizes.tablet)} {
        display: flex;
        flex-direction: row;
        margin-bottom: 4rem;
        padding: 0 3rem;
      }
    `}

  ${({ $variant }) =>
    $variant === ListIconHtmlVariant.Register &&
    css`
      gap: 0 !important;
      padding: 3rem 2rem;
      @media ${mq(sizes.tablet)} {
        padding: 4rem;
      }
    `}
`
export const Item = styled.li<{ $variant?: ListIconHtmlVariant }>`
  ${({ theme }) => theme.textStyles.titleH2}
  display: flex;
  flex-flow: row nowrap;
  align-items: flex-start;
  gap: 2rem;

  ${({ $variant }) =>
    $variant === ListIconHtmlVariant.Positive &&
    css`
      display: flex;
      align-items: center;
      ${({ theme }) => theme.textStyles.textMedium};
      color: ${({ theme }) => theme.colors.bismark};
      gap: 1rem;
      padding: 1.5rem 2rem;
      @media ${mq(sizes.tablet)} {
        flex-direction: column;
        text-align: center;
        padding: 0 1rem;
      }
    `}

  ${({ $variant }) =>
    $variant === ListIconHtmlVariant.Register &&
    css`
      &:not(:last-child) {
        padding-bottom: 2rem;
        border-bottom: 1px solid ${({ theme }) => theme.colors.gainsboro};
      }
      &:not(:first-child) {
        padding-top: 2rem;
      }

      @media ${mq(sizes.tablet)} {
        &:not(:last-child) {
          padding-bottom: 3.6rem;
        }
        &:not(:first-child) {
          padding-top: 3.4rem;
        }
      }
    `}
`

export const ItemContent = styled.div<{ $variant?: ListIconHtmlVariant }>`
  ${({ $variant }) =>
    $variant === ListIconHtmlVariant.Register &&
    css`
      display: flex;
      flex-direction: column;
      width: 100%;
      align-items: start;

      @media ${mq(sizes.tablet)} {
        flex-direction: row;
        justify-content: space-between;
      }
    `}
`

export const IconWrapper = styled.div`
  background: ${({ theme }) => theme.colors.ruby};
  padding: 1.4rem;
  border-radius: 50%;
  width: 6rem;
  height: 6rem;
  align-self: center;
`

export const StyledIcon = styled(Icon)<{ $variant?: ListIconHtmlVariant }>`
  flex: 0 0 auto;

  ${({ $variant }) =>
    $variant === ListIconHtmlVariant.Register &&
    css`
      color: ${({ theme }) => theme.colors.pureWhite};
      width: 3.2rem;
      height: 3.2rem;
    `}
`

export const StyledHtml = styled(Html)<{ $variant?: ListIconHtmlVariant }>`
  flex: 1;
  align-self: center;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;
  font-variation-settings: inherit;
  line-height: inherit;
  & > p {
    margin: 0;
  }

  ${({ $variant }) =>
    $variant === ListIconHtmlVariant.Register &&
    css`
      align-self: start;
      text-transform: initial;
      ${({ theme }) => theme.textStyles.titleH5}
      color: ${({ theme }) => theme.colors.arapawa};
      font-size: 1.7rem;
      & strong {
        ${({ theme }) => theme.textStyles.titleH3}
        color: ${({ theme }) => theme.colors.ruby};
      }
      margin-bottom: 1rem;

      @media ${mq(sizes.tablet)} {
        margin-bottom: 0;
      }
    `}
`

export const StyledActionButton = styled(ActionButton)`
  border-radius: 0.6rem;
`

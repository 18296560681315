import React, { FC } from 'react'

import Tag, { TagProps } from '../../atoms/Tag'

import * as SC from './styled'
import { TagsCloudVariant } from './types'

export type TagsCloudProps = MinimalSeoBlock & {
  className?: string
  title?: string
  tags?: TagProps[]
  variant?: TagsCloudVariant
}

const TagsCloud: FC<TagsCloudProps> = ({
  className,
  htmlTag,
  title,
  htmlTitleTag,
  tags,
  variant = TagsCloudVariant.Default,
}) => {
  return (
    <SC.TagsCloud className={className} as={htmlTag} $variant={variant}>
      {title && (
        <SC.Title as={htmlTitleTag} $variant={variant}>
          {title}
        </SC.Title>
      )}
      <SC.Tags $variant={variant}>
        {tags?.map((tag, i) => (
          <Tag key={i} {...tag} />
        ))}
      </SC.Tags>
    </SC.TagsCloud>
  )
}

export default TagsCloud

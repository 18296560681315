import React, { FC } from 'react'
import { ReactPaginateProps } from 'react-paginate'
import cx from 'classnames'

import { Icons } from '../../atoms/Icon'

import * as SC from './styled'

export type PaginationProps = ReactPaginateProps & {
  className?: string
}

const Pagination: FC<PaginationProps> = ({
  pageCount,
  className,
  onPageChange,
  ...others
}) => {
  return (
    <SC.Container
      className={cx('Pagination', className)}
      pageCount={pageCount}
      onPageChange={onPageChange}
      $isHidden={pageCount <= 1}
      {...others}
      previousLabel={
        <SC.NavPrev icon={Icons.chevronLeft} width={16} height={16} />
      }
      nextLabel={
        <SC.NavNext icon={Icons.chevronRight} width={16} height={16} />
      }
    />
  )
}

export default Pagination
